import React, { useEffect, useState } from 'react'
import { nationalWiseName } from '../constants/constants';
import { shallowEqual, useSelector } from 'react-redux';
import { rangeMapping } from '../mapModels/mapModel';

const MapColorLabel = () => {
    const handleSchemesEvent = useSelector(
        (state) => state?.mapData?.grossEData,
        shallowEqual
      );
     const [handles, setHandles] = useState(handleSchemesEvent);
      useEffect(() => {
        setHandles(handleSchemesEvent);
      }, [handleSchemesEvent]);
      const atIndiaMap=localStorage.getItem('backstate') 
  return (
   <> {atIndiaMap === nationalWiseName &&
    <div className="position-relative">
        <div className="d-flex justify-content-center align-items-center ps-2 pr-2">
          {handles !== "" && rangeMapping[handles] ? (
            <div className="show-color-meaning">
              {["dropout_rate", "pupil_teacher_ratio"].includes(handles) ? (
                <>
                  <div className="show-color-first">
                    <div className="circle4"></div>
                    <div className="text">
                      {rangeMapping[handles][3]}
                      {handles !== "pupil_teacher_ratio" && "%"}{" "}
                      {/* High (reversed to Low) */}
                    </div>
                  </div>
                  <div className="show-color-first">
                    <div className="circle3"></div>
                    <div className="text">
                      {rangeMapping[handles][2]}
                      {handles !== "pupil_teacher_ratio" && "%"} {/* Medium */}
                    </div>
                  </div>
                  <div className="show-color-first">
                    <div className="circle2"></div>
                    <div className="text">
                      {rangeMapping[handles][1]}
                      {handles !== "pupil_teacher_ratio" && "%"}{" "}
                      {/* Low (reversed to High) */}
                    </div>
                  </div>
                  <div className="show-color-first">
                    <div className="circle1"></div>
                    <div className="text">
                      {rangeMapping[handles][0]}
                      {handles !== "pupil_teacher_ratio" && "%"}{" "}
                      {/* Very Low */}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="show-color-first">
                    <div className="circle1"></div>
                    <div className="text">
                      {rangeMapping[handles][0]}% {/* Very Low */}
                    </div>
                  </div>
                  <div className="show-color-first">
                    <div className="circle2"></div>
                    <div className="text">
                      {rangeMapping[handles][1]}% {/* Low */}
                    </div>
                  </div>
                  <div className="show-color-first">
                    <div className="circle3"></div>
                    <div className="text">
                      {rangeMapping[handles][2]}% {/* Medium */}
                    </div>
                  </div>
                  <div className="show-color-first">
                    <div className="circle4"></div>
                    <div className="text">
                      {rangeMapping[handles][3]}%+ {/* High */}
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : null}
        </div>
      </div>
      }
   </>
  )
}

export default MapColorLabel