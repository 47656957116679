// for map define some key and related information
export const rangeMapping = {
    gross_enrollment_ratio: ["Below 85", "85-90", "90-95", "95"],
    dropout_rate: ["15+", "11-15", "6-10", "0-5"],
    transition_rate: ["Below 85", "85-90", "90-95", "95"],
    pupil_teacher_ratio: ["40+", "35-40", "31-35", "30 and Below"],
    schools_with_drinking_water: ["Below 85", "85-90", "90-95", "95"],
    schools_with_electricity_connection: ["Below 70", "70-80", "80-90", "90"],
    school_with: ["Below 85", "85-90", "90-95", "95"],
};

export const keyMap = {
    gross_enrollment_ratio: {
        elementary: "gerElementary",
        secondary: "gerSec",
    },
    dropout_rate: {
        primary: "dropoutPry",
        secondary: "dropoutSec",
    },
    transition_rate: {
        primaryToUpper: "transPryUPry",
        upperToSec: "transUPrySec",
    },
    pupil_teacher_ratio: {
        primary: "ptrPry",
        upperPrimary: "ptrUPry",
    },
    schools_with_drinking_water: {
        default: "schWithDrinkWater",
    },
    schools_with_electricity_connection: {
        default: "schWithElectricity",
    },
    school_with: {
        elementary: "gerElementary",
        secondary: "gerSec",
    },
};

export const keyLabels = {
    gerElementary: "Gross Enrollment Ratio Elementary",
    gerSec: "Gross Enrollment Ratio Secondary",
    dropoutPry: "Dropout Rate Primary",
    dropoutSec: "Dropout Rate Secondary",
    transPryUPry: "Transition Rate Primary to Upper Primary",
    transUPrySec: "Transition Rate Upper Primary to Secondary",
    ptrPry: "Pupil Teacher Ratio Primary",
    ptrUPry: "Pupil Teacher Ratio Upper Primary",
    schWithDrinkWater: "Schools with Drinking Water",
    schWithElectricity: "Schools with Electricity Connection",
};