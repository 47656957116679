import jsPDF from "jspdf";
import "jspdf-autotable";
import { blockWiseName, districtWiseName, excelStyles, stateWiseName } from "../constants/constants";
import { useSelector } from "react-redux";
import { useCallback } from "react";

const generateHeaderStructure = (columns) => {
  const headerRow1 = [];
  const headerRow2 = [];
  const headerRow3 = [];
  const head = [headerRow1, headerRow2, headerRow3];
  const processedColumns =
    columns?.filter((col) => col.headerName === "Serial Number" || !col.hide) ||
    [];
  processedColumns.forEach((col) => {
    const addHeader = (headerRow, content, colSpan = 1) => {
      headerRow.push({ content, colSpan, styles: { halign: "center" } });
    };

    if (!col.children || !Array.isArray(col.children)) {
      if (col.headerName === "Serial Number") {
        addHeader(headerRow1, "");
        addHeader(headerRow2, "");
        addHeader(headerRow3, "Serial Number");
      } else {
        addHeader(headerRow1, "");
        addHeader(headerRow2, "");
        addHeader(headerRow3, col.headerName);
      }
    } else {
      const colSpan = col.children.reduce(
        (sum, rate) => sum + (rate.children ? rate.children.length : 0),
        0
      );
      addHeader(headerRow1, col.headerName, colSpan);

      col.children.forEach((rate) => {
        const subcategoryColSpan = rate.children ? rate.children.length : 1;
        addHeader(headerRow2, rate.headerName, subcategoryColSpan);

        if (rate.children) {
          rate.children.forEach((subCol) =>
            addHeader(headerRow3, subCol.headerName)
          );
        }
      });
    }
  });

  return head;
};

const generateRows = (data, columns) => {
  const rows = [];

  if (Array.isArray(data)) {
    let serialNumber = 1;

    data.forEach((row) => {
      const rowData = [];

      columns?.forEach((col) => {
        if (col.hide && col.headerName !== "Serial Number") return;

        if (col.headerName === "Serial Number") {
          rowData.push(serialNumber++);
        } else {
          const processColumn = (col) => {
            if (col.children) {
              col.children.forEach((child) => processColumn(child));
            } else {
              const value = row[col.field] ?? "";
              rowData.push(value);
            }
          };

          processColumn(col);
        }
      });

      rows.push(rowData);
    });
  } else {
    console.error("Data should be an array but is:", data);
  }

  return rows;
};

export const exportToPDF = ({
  columns,
  data,
  reportName,
  localYear,
  satyamevaImg,
  udiseImg,
  stateName,
  nationalWiseName,
  district,
  block,
  generateTextContent,
}) => {
  const head = generateHeaderStructure(columns);
  const rows = generateRows(data, columns);
const headeLength= head[2].length < 7 ? 9 : head[2].length
  const doc = new jsPDF({ orientation: "landscape", unit: "in", format: [22, headeLength] });

  const addHeader = () => {
    const local_year = localStorage.getItem("year");
    const local_district = localStorage.getItem("map_district_name");
    const local_block = localStorage.getItem("block");
    const reportName = localStorage.getItem("reportName");
    doc.setFontSize(22);
    doc.setTextColor("black");
    doc.setFont("Helvetica", "bold");
    doc.text(
      "Unified District Information System for Education",
      doc.internal.pageSize.width / 2,
      0.7,
      {
        fontStyle: "bold",

        color: "black",
        align: "center",
      }
    );
    doc.setFont("Helvetica", "normal");

    doc.setFontSize(18);

    doc.text(
      "Department of School Education & Literacy, Ministry of Education, Government of India",
      doc.internal.pageSize.width / 2,
      1.1,
      {
        // fontStyle: '',
        color: "black",
        align: "center",
      }
    );

    doc.setFont("Helvetica", "bold");

    doc.setTextColor("black");
    doc.setFontSize(20);

    doc.text(`${reportName}`, doc.internal.pageSize.width / 2, 1.5, {
      fontSize: 12,
      fontStyle: "bold",

      color: "black",
      align: "center",
    });

    doc.setFont("Helvetica", "bold");

    doc.setTextColor("black");
    doc.setFontSize(20);

    doc.setFont("bold");

    doc.setTextColor("black");
    doc.setFontSize(20);

    doc.text(
      `Academic Year : ${local_year}`,
      doc.internal.pageSize.width / 2,
      1.9,
      {
        fontSize: 12,
        color: "black",
        align: "center",
      }
    );

    const textContent = generateTextContent(
      stateName,
      nationalWiseName,
      stateWiseName,
      local_district,
      district,
      districtWiseName,
      local_block,
      block,
      blockWiseName
    );
    if (textContent) {
      doc.text(textContent, doc.internal.pageSize.width / 2, 2.3, {
        fontSize: 12,
        color: "black",
        align: "center",
      });
    }
    const leftMargin = 0.1;
    const topLeftX = leftMargin;
    const topLeftY = 0;
    const imgWidth = 2;
    const imgHeight = 2;

    doc.setFontSize(20);
    doc.setTextColor("blue");
    doc.addImage(satyamevaImg, "PNG", topLeftX, topLeftY, imgWidth, imgHeight);

    doc.setTextColor("blue");
    doc.setFont("bold");
    const pageWidthE = doc.internal.pageSize.getWidth();
    const pageHeightE = doc.internal.pageSize.getHeight();

    const imgWidthE = 2.8;
    const imgHeightE = 1.4;
    const marginRight = 0.7;
    const topRightX = pageWidthE - imgWidthE - marginRight;
    const topRightY = 0.3;
    doc.addImage(udiseImg, "JPG", topRightX, topRightY, imgWidthE, imgHeightE);
  };

  const addFooter = (doc, totalPages, formattedDate) => {
    for (let i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      doc.setTextColor(0, 0, 0);

      doc.setFontSize(14);
      doc.text(
        `Page ${i} of ${totalPages}`,
        doc.internal.pageSize.width / 2,
        doc.internal.pageSize.height - 0.2,
        {
          align: "center",
        }
      );
      doc.text(
        `Report generated on: ${formattedDate}`,
        doc.internal.pageSize.width - 1,
        doc.internal.pageSize.height - 0.2,
        {
          align: "right",
        }
      );
    }
  };

  const formattedDate = new Date().toLocaleDateString("en-GB", {
    weekday: "long",
    day: "2-digit",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  addHeader();

  doc.autoTable({
    head,
    body: rows,
    theme: "grid",
    startY: 2.7,
    styles: {
      cellPadding: 0.15,
      lineColor: [0, 0, 0],
      lineWidth: 0.001,
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      halign: "center",
    },
    headStyles: {
      fontSize: 14,
      fontStyle: "bold",
      textColor: [0, 0, 0],
      cellPadding: 0.2,
      halign: "center",
    },
    tableLineWidth: 0.05,

    didParseCell: function (data) {
      const isTotalRow = data.row.raw && data.row.raw.includes("Total");
      if (isTotalRow) {
        data.cell.styles.fontStyle = "bold";
      }
      const isOverallRow = data.row.raw && data.row.raw.includes("Overall");
      if (isOverallRow) {
        data.cell.styles.fontStyle = "bold";
      }
      const isSerialNumber =
        data.row.raw && data.row.raw.includes("Serial Number");
      if (isSerialNumber) {
        data.cell.styles.halign = "right";
      }
      const isSocialCat =
        data.row.raw && data.row.raw.includes("Social Category");
      if (isSocialCat) {
        data.cell.styles.halign = "left";
      }
    },
  });

  addFooter(doc, doc.internal.getNumberOfPages(), formattedDate);

  doc.save(`${reportName}.pdf`);
};

// This is fo excell Only for all component

export const useExportToExcel = (
  gridApi,
  report,
  local_year,
  stateName,
  nationalWiseName,
  stateWiseName,
  local_district,
  district,
  districtWiseName,
  local_block,
  block,
  blockWiseName,
  generateTextContent
) => {
  const textContent = generateTextContent(
    stateName,
    nationalWiseName,
    stateWiseName,
    local_district,
    district,
    districtWiseName,
    local_block,
    block,
    blockWiseName
  );
  const formattedDate = new Date().toLocaleDateString("en-GB", {
    weekday: "long",
    day: "2-digit",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  const exportToExcel = useCallback(() => {
    if (gridApi) {
      const allData = [];
      const visibleColumns = gridApi.api.getAllDisplayedColumns();
  
      const columnHeaders = visibleColumns.map((column) => ({
        headerName: column.getColDef().headerName,
        field: column.getColDef().field,
        styleId: 'headerStyle', 
      }));
  
      columnHeaders.unshift({
        headerName: "S.NO.",
        styleId: 'headerStyle',
      });
  
      gridApi.api.forEachNode((node, index) => {
        const data = node.data;
        const rowDataWithSerial = { ...data };
        allData.push(rowDataWithSerial);
      });
  
      const columnKeys = columnHeaders.map((column) => column.field);
      const columnNames = columnHeaders.map((column) => column.headerName);
      gridApi.api.exportDataAsExcel({
        processHeaderCallback: (params) => params.column.getColDef().headerName, 
        processCellCallback: (params) => params.value, 
        rowData: allData,
        fileName: report.report_name,
        sheetName: "UDISE+",
        columnKeys: columnKeys,
        columnNames: columnNames,
        excelStyles: excelStyles, 
        prependContent: getPrependContent(report.report_name, local_year, textContent, formattedDate, columnNames),
        appendContent: getAppendContent(columnNames),
        autoSize: true,
      });
      
    }
  }, [gridApi, report, local_year, textContent,excelStyles]);
  

  return { exportToExcel };
};

const getPrependContent = (reportName, localYear, textContent, formattedDate, columnNames) => {
  const totalColumns = Math.round(columnNames.length - 2);
  const createCenteredRow = (value, styleId, headerName = columnNames,rowIndex) => ({
    cells: [
      {
        styleId,
        data: { value, type: "String" },
        mergeAcross: totalColumns,
      },
      headerName ? { value: headerName, type: "String" } : {} 
    ],
  });
 
  
  return [
    createCenteredRow(reportName, "subHeaderStyleRight", "", 1),
    createCenteredRow(`Report generated on: ${formattedDate}`, "subHeaderStyleRight", "", 2),
    createCenteredRow(`Academic Year: ${localYear}`, "subHeaderStyle", "",3),
    createCenteredRow(textContent, "subHeaderStyle", "", 4),
    createCenteredRow("", "lastsubHeaderStyle", "", 5),
  ];
};


const getAppendContent = (columnNames) => {
  const totalColumns = Math.round(columnNames.length - 2); 
  const createCenteredRow = (value, styleId) => ({
    cells: [
      {
        styleId, // Apply the style based on the styleId
        data: { value, type: "String" },
        mergeAcross: totalColumns, // Merge across all columns
        style: {
          fill: {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "#B0C4DE" }, 
          },
        },
      },
    ],
  });

  return [
    // Example of appending some additional content at the end of the table
    createCenteredRow("" ,"footerStyle"),
  ];
};






