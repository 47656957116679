import { createSlice } from "@reduxjs/toolkit";
import { fetchArchiveServicesEnrolmentRelatedIndicators } from "../thunks/archiveServicesThunk";
const acData = localStorage.getItem("acData");
const parsedACData = JSON.parse(acData);

const enrollementSlice4014 = createSlice({
  name: "enrollement/report-4014",
  initialState: {
    data: {
      data: [],
      statusCode: 0,
      message: "",
      success: false,
    },
    isLoading: false,
    isError: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchArchiveServicesEnrolmentRelatedIndicators.pending,
        (state) => {
          state.isLoading = true;
        }
      )
      .addCase(
        fetchArchiveServicesEnrolmentRelatedIndicators.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.data = action.payload.data === "" ? [] : action.payload;
        
        }
      )
      .addCase(
        fetchArchiveServicesEnrolmentRelatedIndicators.rejected,
        (state, action) => {
          state.isLoading = false;
          state.isError = true;
        }
      );
    //   .addCase(updateFilterState.fulfilled, (state, action) => {
    //     state.data.data = action.payload
    //     state.isLoading = false;
    //   });
  },
});

export default enrollementSlice4014.reducer;
