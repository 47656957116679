import { createSlice } from "@reduxjs/toolkit";
import {fetchYearData} from "../thunks/yearThunk"

const headerSlice = createSlice({
  name: 'header',
  initialState: {
    headerName: "Education Dashboard",
    removeBeforeAfterClass: 'main-card-home',
    isViewDataByShow: true,
    regionName: "States",
    showDistricts: false,
    currentIndex: 0,
    show: false,
    activeTab: "table",
    selectYear: "22-27",
    reserveUpdatedFilter: {},
    arrGroupedGraph3013Data: {
      graphData: [],
      pieGraphData: [],
      pieGraphDatas:[]
    },
 
    selectedField3013graph: {
      field: "totSchLandAvail",
      label: "Land Available"
    },
    pieGraph3013mngSchSelected:"School Management",
    selectYearId:10,
    isAllReports: false,
    onDrilldownMap:false

  },
  reducers: {
    handleShowDistrict(state, action) {
      state.showDistricts = action.payload
    },
    updateHeaderName: (state, action) => {
      state.headerName = action.payload;
      state.isAllReports = action.payload === "All Reports";
    },
    removeBeforeAfterClass(state, action) {
      state.removeBeforeAfterClass = action.payload
    },
    handleViewDataByShow(state, action) {
      state.isViewDataByShow = action.payload
    },
    handleRegionName(state, action) {
      state.regionName = action.payload
    },
    handleCurrentIndex(state, action) {
      state.currentIndex = action.payload
    },
    handleShowFilter(state, action) {
      state.show = action.payload
    },
    handleActiveTabs(state, action) {
      state.activeTab = action.payload
    },
    handleSelectYear(state, action) {
      state.selectYear = action.payload
    },


    setReserveUpdatedFilter(state, action) {
      state.reserveUpdatedFilter = {
        regionType: action.payload.regionType,
        regionCode: action.payload.regionCode,
        dType: action.payload.dType,
        dCode: action.payload.dCode,
        dashboardRegionType: action.payload.dashboardRegionType,
        dashboardRegionCode: action.payload.dashboardRegionCode,
        valueType: action.payload.valueType,
        locationCode: action.payload.locationCode,
        managementCode: action.payload.managementCode,
        categoryCode: action.payload.categoryCode,
        schoolTypeCode: action.payload.schoolTypeCode,
        yearId: action.payload.yearId
      }
    },
    setArrGroupedGraph3013Data(state, action) {
      state.arrGroupedGraph3013Data = {
        graphData: action.payload.graphData || [],
        pieGraphData: action.payload.pieGraphData || [],
        pieGraphDatas:action.payload.pieGraphDatas || []
      };
    },
    
    setpieGraph3013mngSchSelected(state, action) {
      state.pieGraph3013mngSchSelected = action.payload
    },
    setSelectedField3013graph(state, action) {
      state.selectedField3013graph = action.payload
    },
    setSelectYearId(state, action) {
      state.selectYearId = action.payload
    },
      
    setAcadSelctedYearId(state, action) {
      state.acadSelctedYearId = action.payload
    },


    setonDrilldownMap(state, action){
      state.onDrilldownMap = action.payload
    }
  },

  extraReducers: (builder) => {
 
    builder
    .addCase(fetchYearData.pending, (state) => {
    state.isLoading = true;
    })
    .addCase(fetchYearData.fulfilled, (state, action) => {
    state.isLoading = false;
    state.selectYearId = action.payload.data[0].yearId
    state.selectYear =action.payload.data[0].yearDesc
    const obj = {
      yearId:action.payload.data[0].yearId,
      yearDesc:action.payload.data[0].yearDesc
    }
    localStorage.setItem("acData",JSON.stringify(obj))
    localStorage.setItem("acDataForReset",JSON.stringify(obj))
    })
    .addCase(fetchYearData.rejected, (state, action) => {
    state.isLoading = false;
    state.isError = true;
    })
    
    },






})

export const { updateHeaderName, removeBeforeAfterClass, handleViewDataByShow, handleRegionName, handleShowDistrict, handleCurrentIndex, handleDisableBtn, handleShowFilter, handleActiveTabs, handleSelectYear, setReserveUpdatedFilter, setArrGroupedGraph3013Data, setSelectedField3013graph,setpieGraph3013mngSchSelected,setSelectYearId, setonDrilldownMap ,setAcadSelctedYearId } = headerSlice.actions
export default headerSlice.reducer