import React, { useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useDispatch } from "react-redux";
import {
  handleShowDistrict,
  setReserveUpdatedFilter,
  updateHeaderName,
} from "../../redux/slice/headerSlice";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { modifiedFilterObjForReset, modifiedFilterObjResetDashboard, urls } from "../../constants/constants";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { allFilter } from "../../redux/slice/schoolFilterSlice";
import { fetchDashboardData, fetchMaptatsData, fetchMaptatsOtherData, fetchSchoolStatsData, fetchSchoolStatsIntData, fetchStudentStatsData, fetchStudentStatsIntData, fetchTeachersStatsData, fetchTeachersStatsIntData } from "../../redux/thunks/dashboardThunk";
import { fetchArchiveServicesGraphSchoolData } from "../../redux/thunks/archiveServicesThunk";

export default function SlidingTabBar(headerName) {
 
  const header_name = useSelector((state) => state.header);
    const headerSlice = useSelector((state) => state.header);
  useEffect(() => {
    sessionStorage.setItem("handle", "");
  }, [header_name]);
  const location = useLocation();
  const navigate = useNavigate();
  const path_name = location.pathname.split("-")[0]?.toUpperCase()?.slice(1);
  const dispatch = useDispatch();
  function handleSelect(e) {
    dispatch(updateHeaderName(e));
    navigate("/");
  }
  function handleNavigate(e) {
    dispatch(updateHeaderName(e));
  }

  const { t } = useTranslation();
// useEffect(()=>{
//   if(location.pathname === "/reports"){
//     dispatch(fetchMaptatsData(modifiedFilterObjForReset));
//     dispatch(fetchMaptatsOtherData(modifiedFilterObjForReset));
//      dispatch(setReserveUpdatedFilter(modifiedFilterObjResetDashboard));
//     dispatch(allFilter(modifiedFilterObjResetDashboard));
//     handleAPIsCallAccordingToFilter(modifiedFilterObjResetDashboard);
//   }
// },[location.pathname])

const handleAPIsCallAccordingToFilter = (obj) => {
  if (headerSlice.headerName === "Education Dashboard") {
    dispatch(fetchDashboardData(obj));
    dispatch(fetchSchoolStatsData(obj));
    dispatch(fetchSchoolStatsIntData(obj));
    dispatch(fetchTeachersStatsData(obj));
    dispatch(fetchTeachersStatsIntData(obj));
    dispatch(fetchStudentStatsData(obj));
    dispatch(fetchStudentStatsIntData(obj));
  } else if (headerSlice.headerName === "School Dashboard") {
    dispatch(fetchSchoolStatsData(obj));
    dispatch(fetchSchoolStatsIntData(obj));
    dispatch(fetchArchiveServicesGraphSchoolData(obj));
  } else if (headerSlice.headerName === "Teacher Dashboard") {
    dispatch(fetchTeachersStatsData(obj));
    dispatch(fetchTeachersStatsIntData(obj));
  } else {
    dispatch(fetchStudentStatsData(obj));
    dispatch(fetchStudentStatsIntData(obj));
  }
};
  return (
    <>
      {location.pathname === "/reports" || urls.includes(location.pathname) ? (
        <>
          {location.pathname === "/reports" ? (
            <Tabs
              defaultActiveKey={"All Reports"}
              id="uncontrolled-tab-example"
              onSelect={(e) => handleSelect(e)}
            >
              <Tab
                eventKey="Education Dashboard"
                title={t("education_dashboard")}
              ></Tab>
              <Tab
                eventKey="School Dashboard"
                title={t("school_dashboard")}
              ></Tab>
              <Tab
                eventKey="Teacher Dashboard"
                title={t("teacher_dashboard")}
              ></Tab>
              <Tab
                eventKey="Student Dashboard"
                title={t("student_dashboard")}
              ></Tab>
              {/* <Tab eventKey="All Reports" title={t("all_reports")}></Tab> */}
            </Tabs>
          ) : (
            <div className="header-breadcrumb">
              <div className="title-brd">
                <Link
                  to="/reports"
                  className="text-black"
                  onClick={() => handleNavigate("All Reports")}
                >
                  Reports
                </Link>{" "}
                <span className="material-icons-round">chevron_right</span>{" "}
                <span className="internalpagename">{path_name}</span>{" "}
              </div>
            </div>
          )}
        </>
      ) : (
        <Tabs
          defaultActiveKey={header_name.headerName}
          id="uncontrolled-tab-example"
          onSelect={(e) => handleSelect(e)}
        >
          <Tab
            eventKey="Education Dashboard"
            title={t("education_dashboard")}
          ></Tab>
          <Tab eventKey="School Dashboard" title={t("school_dashboard")}></Tab>
          <Tab
            eventKey="Teacher Dashboard"
            title={t("teacher_dashboard")}
          ></Tab>
          <Tab
            eventKey="Student Dashboard"
            title={t("student_dashboard")}
          ></Tab>
          {/* <Tab eventKey="All Reports" title={t("all_reports")}></Tab> */}
        </Tabs>
      )}
    </>
  );
}
