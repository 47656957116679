import { createAsyncThunk } from "@reduxjs/toolkit";
// import axios  from '../../services/utility';

import utilityNew from "../../services/utilityNew";

const fetchYearData = createAsyncThunk(
  "year/fetchYearData",
  async () => {
    const response = await utilityNew.get(`v1.1/acad-year-master/public`);
    return response?.data;
  }
);
const removeDateYear = createAsyncThunk(
  "year/removeYear",
  async () => {
    return [];
  }
);
export  {fetchYearData,removeDateYear};