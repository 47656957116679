import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  fetchStudentStatsData,
  fetchDashboardData,
} from "../../redux/thunks/dashboardThunk";
import { useDispatch, useSelector } from "react-redux";
import { fetchYearData } from "../../redux/thunks/yearThunk";

export function EducationDashboardGraphA({
  t,
  dashDataSchool,
  dashDataTeacher,
  dashDataStudent,
  dashData,
}) {
  const schoolFilter = useSelector((state) => state?.schoolFilter);
  const filterObj = structuredClone(schoolFilter);
  const dispatch = useDispatch();
  const [tabsState, setTabsState] = useState("School");
  const isMobile = window.matchMedia("(max-width: 767px)").matches;
  
  const [mgtGraph, setMgtGraph] = useState([
    {
      name: t("government"),
      y: 1,
      color: "#F5BF55",
    },
    {
      name: t("private"),
      y: 1,
      color: "#E6694A",
    },
    {
      name: t("aided"),
      y: 1,
      color: "#BCE263",
    },
    {
      name: t("others"),
      y: 1,
      color: "#751539",
    },
  ]);

  const handleMgtWiseGraph = (data) => {
    let arr = [];
    const totSchoolGovt = parseFloat(data?.totSchoolGovt || 0);
    const totSchoolGovtAided = parseFloat(data?.totSchoolGovtAided || 0);
    const totSchoolPvt = parseFloat(data?.totSchoolPvt || 0);
    const totSchoolOther = parseFloat(data?.totSchoolOther || 0);
    const totalSchools = totSchoolGovt + totSchoolGovtAided + totSchoolPvt + totSchoolOther;
    const calculatePercentage = (value, total) => total ? ((value / total) * 100).toFixed(2) : 0;
  
    arr.push(
      {
        name: t("government"),
        y: parseFloat(calculatePercentage(totSchoolGovt, totalSchools)),
        color: "#F5BF55",
      },
      {
        name: t("private"),
        y: parseFloat(calculatePercentage(totSchoolPvt, totalSchools)),
        color: "#E6694A",
      },
      {
        name: t("aided"),
        y: parseFloat(calculatePercentage(totSchoolGovtAided, totalSchools)),
        color: "#BCE263",
      },
      {
        name: t("others"),
        y: parseFloat(calculatePercentage(totSchoolOther, totalSchools)),
        color: "#751539",
      }
    );
  
    setMgtGraph(arr);
  
    arr = [];
  
    arr.push(
      {
        name: "Male",
        data: [1, 1, 1, 1, 1],
        color: "#751539",
      },
      {
        name: "Female",
        data: [1, 1, 1, 1, 1],
        color: "#E6694A",
      }
    );
  };
  

  const handleTeacherGraph = (data) => {
    let arr = [];
  
    const totTchGovt = parseFloat(data?.totTchGovt || 0);
    const totTchGovtAided = parseFloat(data?.totTchGovtAided || 0);
    const totTchPvt = parseFloat(data?.totTchPvt || 0);
    const totTchOther = parseFloat(data?.totTchOther || 0);
  
    // Calculate the total
    const totalTeachers = totTchGovt + totTchGovtAided + totTchPvt + totTchOther;
  
    // Helper function to calculate percentage
    const calculatePercentage = (value, total) => total ? ((value / total) * 100).toFixed(2) : 0;
  
    arr.push(
      {
        name: t("government"),
        y: parseFloat(calculatePercentage(totTchGovt, totalTeachers)),
        color: "#F5BF55",
      },
      {
        name: t("private"),
        y: parseFloat(calculatePercentage(totTchPvt, totalTeachers)),
        color: "#E6694A",
      },
      {
        name: t("aided"),
        y: parseFloat(calculatePercentage(totTchGovtAided, totalTeachers)),
        color: "#BCE263",
      },
      {
        name: t("others"),
        y: parseFloat(calculatePercentage(totTchOther, totalTeachers)),
        color: "#751539",
      }
    );
  
    setMgtGraph(arr);
  };
  
  const handleStudentGraph = (data) => {
    let arr = [];
  
    const totStudentGovt = parseFloat(data?.totStudentGovt || 0);
    const totStudentGovtAided = parseFloat(data?.totStudentGovtAided || 0);
    const totStudentPvt = parseFloat(data?.totStudentPvt || 0);
    const totStudentOther = parseFloat(data?.totStudentOther || 0);
    const totalStudents = totStudentGovt + totStudentGovtAided + totStudentPvt + totStudentOther;
    const calculatePercentage = (value, total) => total ? ((value / total) * 100).toFixed(2) : 0;
  
    arr.push(
      {
        name: t("government"),
        y: parseFloat(calculatePercentage(totStudentGovt, totalStudents)),
        showInLegend: true,
        color: "#F5BF55",
      },
      {
        name: t("private"),
        y: parseFloat(calculatePercentage(totStudentPvt, totalStudents)),
        showInLegend: true,
        color: "#E6694A",
      },
      {
        name: t("aided"),
        y: parseFloat(calculatePercentage(totStudentGovtAided, totalStudents)),
        showInLegend: true,
        color: "#BCE263",
      },
      {
        name: t("others"),
        y: parseFloat(calculatePercentage(totStudentOther, totalStudents)),
        showInLegend: true,
        color: "#751539",
      }
    );
  
    setMgtGraph(arr);
  };
  
  const handleTabs = (e) => {
    if (e === "School") {
      handleMgtWiseGraph(dashDataSchool);
    }
    if (e === "Teacher") {
      handleTeacherGraph(dashDataTeacher);
    }
    if (e === "Student") {
      handleStudentGraph(dashDataStudent);
    }

    setTabsState(e);
  };
 
  useEffect(() => {
    if (tabsState === "School") {
      handleMgtWiseGraph(dashDataSchool);
    } else if (tabsState === "Teacher") {
      handleTeacherGraph(dashDataTeacher);
    } else if (tabsState === "Student") {
      handleStudentGraph(dashDataStudent);
    }
  }, [
    t,
    dashDataSchool,
    dashDataStudent,
    dashData,
    dashDataTeacher,
    dashDataTeacher,
  ]);

  return (
    <div className="row">
      <div className="col-md-12 col-lg-12">
        <div className="impact-box-content-education ">
          <div className="text-btn-d">
            <h2 className="heading-sm">
              {t("education_data_by_management_type")}
            </h2>

          </div>

          <Tabs
            defaultActiveKey={tabsState}
            id="uncontrolled-tab-example"
            className=""
            onSelect={handleTabs}>
            <Tab eventKey="School" title={t("school")}>
              <div className="piechart-box row mt-4">
                <div className="col-md-12">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      chart: {
                        type: "pie",
                        marginTop: 50,
                        events: {
                          beforePrint: function () {
                            this.exportSVGElements[0].box.hide();
                            this.exportSVGElements[1].hide();
                          },
                          afterPrint: function () {
                            this.exportSVGElements[0].box.show();
                            this.exportSVGElements[1].show();
                          },

                        },
                      },
                      events: {
                        beforePrint: function () { },
                        afterPrint: function () { },
                      },
                      title: {
                        text: t("education_data_by_management_type"),
                      },
                      tooltip: {
                        valueSuffix: "%",
                        valueDecimals: 2,
                      },
                      credits: {
                        enabled: false,
                      },
                      plotOptions: {
                        pie: {
                          size: "100%",
                          dataLabels: {
                            enabled: true,
                            distance: -40,
                            alignTo: isMobile? 'connectors' : null,
                            connectorShape: 'crookedLine',
                            crookDistance: '100%',
                            formatter: function () {
                              return "<b>" + this.point.y + "%";
                            },
                            style: {
                              fontSize: "16px",
                              textOutline: "none",
                              opacity: 0.7,
                            },
                          },
                          center: ["50%", "50%"],
                          showInLegend: true,
                        },
                        series: {
                          allowPointSelect: true,
                          cursor: "pointer",
                          dataLabels: [
                            {
                              enabled: true,
                              distance: 20,
                            },
                            {
                              enabled: false,
                              distance: -40,
                              format: "{point.y:.2f}%",
                              style: {
                                fontSize: "0.6em",
                                textOutline: "none",
                                opacity: 0.7,
                              },

                              filter: {
                                operator: ">",
                                property: "percentage",
                                value: 0,
                              },
                            },
                          ],
                        },
                      },
                      legend: {
                        // title: {
                        //     text: "Key",
                        //     style: {
                        //       fontSize: '18px',
                        //     },
                        //     x:0
                        //   },
                        layout: "horizontal",
                        align: "center",
                        verticalAlign: "bottom",
                        itemMarginTop: 10,
                        itemMarginBottom: 10,
                      },
                      series: [
                        {
                          name: t("percentage"),
                          colorByPoint: true,
                          data: mgtGraph,
                        },
                      ],

                      exporting: {
                        filename: t("education_data_by_management_type"),
                        csv: {
                          columnHeaderFormatter: function (item) {
                            if (!item || item instanceof Highcharts.Axis) {
                              return t("category");
                            }
                            return item.name;
                          },
                        },
                        // buttons: {
                        //   contextButton: {
                        //     y: -45, 

                        //   }
                        // }

                      },
                    }}
                    // allowChartUpdate={true}
                    immutable={true}

                  />
                </div>
              </div>
            </Tab>
            <Tab eventKey="Teacher" title={t("teacher")}>
              <div className="piechart-box row mt-4">
                <div className="col-md-12">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      chart: {
                        type: "pie",
                        marginTop: 50,
                        events: {
                          beforePrint: function () {
                            this.exportSVGElements[0].box.hide();
                            this.exportSVGElements[1].hide();
                          },
                          afterPrint: function () {
                            this.exportSVGElements[0].box.show();
                            this.exportSVGElements[1].show();
                          },
                        },
                      },
                      events: {
                        beforePrint: function () { },
                        afterPrint: function () { },
                      },
                      title: {
                        text: t("education_data_by_management_type"),
                      },
                      tooltip: {
                        valueSuffix: "%",
                        valueDecimals: 2,
                      },
                      credits: {
                        enabled: false,
                      },
                      plotOptions: {
                        pie: {
                          size: "100%",
                          dataLabels: {
                            enabled: true,
                            distance: -40,
                            alignTo: isMobile? 'connectors' : null,
                            connectorShape: 'crookedLine',
                            crookDistance: '100%',
                            formatter: function () {
                              return "<b>" + this.point.y + "%";
                            },
                            style: {
                              fontSize: "16px",
                              textOutline: "none",
                              opacity: 0.7,
                            },
                          },
                          center: ["50%", "50%"],
                          showInLegend: true,
                        },
                        series: {
                          allowPointSelect: true,
                          cursor: "pointer",
                          dataLabels: [
                            {
                              enabled: true,
                              distance: 20,
                            },
                            {
                              enabled: false,
                              distance: -40,
                              format: "{point.y:.2f}%",
                              style: {
                                fontSize: "0.6em",
                                textOutline: "none",
                                opacity: 0.7,
                              },

                              filter: {
                                operator: ">",
                                property: "percentage",
                                value: 0,
                              },
                            },
                          ],
                        },
                      },
                      legend: {
                        // title: {
                        //     text: "Key",
                        //     style: {
                        //       fontSize: '18px',
                        //     }
                        //   },
                        layout: "horizontal",
                        align: "center",
                        verticalAlign: "bottom",
                        itemMarginTop: 10,
                        itemMarginBottom: 10,
                      },
                      series: [
                        {
                          name: t("percentage"),
                          colorByPoint: true,
                          data: mgtGraph,
                        },
                      ],
                      exporting: {
                        filename: t("education_data_by_management_type"),
                        csv: {
                          columnHeaderFormatter: function (item) {
                            if (!item || item instanceof Highcharts.Axis) {
                              return t("category");
                            }
                            return item.name;
                          },
                        },
                      },
                    }}
                    // allowChartUpdate={true}
                    immutable={true}
                  />
                </div>
              </div>
            </Tab>
            <Tab eventKey="Student" title={t("student")}>
              <div className="piechart-box row mt-4">
                <div className="col-md-12">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      chart: {
                        type: "pie",
                        marginTop: 50,
                        events: {
                          beforePrint: function () {
                            this.exportSVGElements[0].box.hide();
                            this.exportSVGElements[1].hide();
                          },
                          afterPrint: function () {
                            this.exportSVGElements[0].box.show();
                            this.exportSVGElements[1].show();
                          },
                        },
                      },
                      events: {
                        beforePrint: function () { },
                        afterPrint: function () { },
                      },
                      title: {
                        text: t("education_data_by_management_type"),
                      },
                      tooltip: {
                        valueSuffix: "%",
                        valueDecimals: 2,
                      },
                      credits: {
                        enabled: false,
                      },
                      plotOptions: {
                        pie: {
                          size: "100%",
                          dataLabels: {
                            enabled: true,
                            distance: -40,
                            alignTo: isMobile? 'connectors' : null,
                            connectorShape: 'crookedLine',
                            crookDistance: '100%',
                            formatter: function () {
                              return "<b>" + this.point.y + "%";
                            },
                            style: {
                              fontSize: "16px",
                              textOutline: "none",
                              opacity: 0.7,
                            },
                          },
                          center: ["50%", "50%"],
                          showInLegend: true,
                        },
                        series: {
                          allowPointSelect: true,
                          cursor: "pointer",
                          dataLabels: [
                            {
                              enabled: true,
                              distance: 20,
                            },
                            {
                              enabled: false,
                              distance: -40,
                              format: "{point.y:.2f}%",
                              style: {
                                fontSize: "0.6em",
                                textOutline: "none",
                                opacity: 0.7,
                              },

                              filter: {
                                operator: ">",
                                property: "percentage",
                                value: 0,
                              },
                            },
                          ],
                        },
                      },
                      legend: {
                        // title: {
                        //     text: "Key",
                        //     style: {
                        //       fontSize: '18px',
                        //     }
                        //   },
                        layout: "horizontal",
                        align: "center",
                        verticalAlign: "bottom",
                        itemMarginTop: 10,
                        itemMarginBottom: 10,
                      },
                      series: [
                        {
                          name: t("percentage"),
                          colorByPoint: true,
                          data: mgtGraph,
                        },
                      ],
                      exporting: {
                        filename: t("education_data_by_management_type"),
                        csv: {
                          columnHeaderFormatter: function (item) {
                            if (!item || item instanceof Highcharts.Axis) {
                              return t("category");
                            }
                            return item.name;
                          },
                        },
                      },
                    }}
                    // allowChartUpdate={true}
                    immutable={true}
                  />
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}
