import axios from 'axios'
import { selectedDYearId } from '../constants/constants';
const apiClient =  axios.create({
    baseURL: 'https://demo.udiseplus.gov.in/archive-services/v1.1/',
    headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer df;lkjz8lke4lk345kljsdfkjdfgkljsf08994a/sdfljsdf879w4ra/sdflksdflksdf",
        "Identity":"test"
    }
})

apiClient.interceptors.request.use(
    (config) => {
     
        return config;
    },
    (error) => {
        // Handle request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
apiClient.interceptors.response.use(
    (response) => {
  
        return response;
    },
    (error) => {
        // Handle response error
      
        return Promise.reject(error);
    }
);

export default apiClient;
